import React from "react";
import { graphql } from "gatsby";

// for html from frontmatter
import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';

import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import Quote from "../components/Quote.js"
import TeacherLink from "../components/TeacherLink.js"

export default function Template({data}) {

    let page = data.markdownRemark
    const frontmatter = page.frontmatter
    const featuredImgFluid = frontmatter.featuredImage.childImageSharp.gatsbyImageData
    const excerpt = remark()
                    .use(recommended)
                    .processSync(frontmatter.excerpt).toString();
    const content = page.html.split('<hr>');
    const body = content[0];
    const schedule = content[1];
    const quote = content[2]



    return (
        <Layout currentPath={frontmatter.path}>
            <Seo title={page.frontmatter.title} />
            <div className="o-section -main-section">
                <div className="container">
                    <div className="row flex-md-row-reverse">
                        <div className="col-md-7 ml-auto">
                            <div className="o-section__header u-color-bg:white u-py:4">
                                <div className="u-ta:c u-ta-md:l  u-color:dark-theme">
                                    <h1 className="u-mb:1">{frontmatter.title}</h1>
                                    <span className="ts:lead">{excerpt}</span>
                                </div>
                            </div>
                            <div className="u-pt:4 u-pt-md:6" dangerouslySetInnerHTML={{ __html: body }} />
                        </div>
                        <div className="col-md-5 u-pr-md:3 u-pr-lg:6 u-overflow:t u-mt-md:4 u-mt:2">
                            <GatsbyImage image={featuredImgFluid}  className="u-overflow:l c-classes-img"/>
                            <div className="u-pt:4 ts:small" dangerouslySetInnerHTML={{ __html: schedule}} />
                        </div>
                    </div>
                </div>
            </div>


            {quote &&
            <Quote body={quote} />
            }


            <TeacherLink />

        </Layout>
    )
}

export const pageQuery = graphql`
    query ClassPageQuery($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                excerpt
                featuredImage {
                  childImageSharp {
                    gatsbyImageData(
                        width: 656
                        quality: 90
                        placeholder: BLURRED
                    )
                  }
                }
            }
        }
    }
`
